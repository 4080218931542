<template>
  <i-modal
    dontSave
    :value="value"
    :load="loading"
    :max-width="'1200px'"
    @input="$emit('input', $event)"
  >
    <invoicefield :order="toEdit" />
  </i-modal>
</template>
<script>
import invoicefield from '../../components/invoice/invoiceDocument.vue'
export default {
  components: {
    invoicefield
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    toEdit: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      loading: false,
      invoice: {
        number: ''
      },
      edit: false
    }
  },
  watch: {
    value: {
      immediate: true,
      handler(val) {
        if (val) {
          this.edit = this.toEdit !== null
          this.invoice = this.toEdit
        }
      }
    }
  }
}
</script>
