var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"px-5",attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('i-btn',_vm._g({attrs:{"title":_vm.$t('print', { value: _vm.$tc('invoice', 1) }),"small":"","absolute":"","right":"","color":"error","icon":"fa-print","classes":"mx-1","outlined":""}},on))]}}])})],1),_c('v-col',{class:{
        'grey--text': true,
        'text-uppercase': true,
        'font-weight-black': true,
        headline: _vm.$vuetify.breakpoint.lgAndUp,
        title: _vm.$vuetify.breakpoint.mdAndDown,
        'pr-3': _vm.$vuetify.breakpoint.smAndUp
      },attrs:{"cols":"12","sm":"6","md":"4"}},[_vm._v(" "+_vm._s(_vm.$t('receivable'))+" #"+_vm._s(_vm.receivable.code)+" ")]),_c('v-col',{class:{
        'grey--text': true,
        'text-uppercase': true,
        'font-weight-black': true,
        headline: _vm.$vuetify.breakpoint.lgAndUp,
        title: _vm.$vuetify.breakpoint.mdAndDown
      },attrs:{"cols":"12","sm":"6","md":"4"}},[_vm._v(" "+_vm._s(`${_vm.$t('date')} ${_vm.dateFormat(_vm.receivable.date_init)}`)+" ")]),_c('v-col',{class:{
        'pr-3': _vm.$vuetify.breakpoint.smAndUp
      },attrs:{"cols":"12","sm":"6","md":"4"}},[_c('i-money',{class:{
          'grey--text': true,
          'text-uppercase': true,
          'font-weight-black': true,
          headline: _vm.$vuetify.breakpoint.lgAndUp,
          title: _vm.$vuetify.breakpoint.mdAndDown
        },attrs:{"value":_vm.receivable.balance,"label":`${_vm.$tc('balance', 1)}:`,"mode":"text"}})],1)],1),_c('v-subheader',{class:{
      'primary--text': true,
      'font-weight-black': true
    }},[_vm._v(" "+_vm._s(_vm.$t('section', { value: _vm.$tc('customer', 1) }))+" ")]),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{class:{
        'pr-3': _vm.$vuetify.breakpoint.smAndUp
      },attrs:{"cols":"12","sm":"6","md":"4","lg":"3"}},[_c('v-text-field',{attrs:{"outlined":"","name":"customer","disabled":"","value":_vm.receivable.customer.company,"readonly":"","label":_vm.$tc('customerCompany', 1)}})],1),_c('v-col',{class:{
        'pr-3': _vm.$vuetify.breakpoint.smAndUp
      },attrs:{"cols":"12","sm":"6","md":"4","lg":"3"}},[_c('v-text-field',{attrs:{"outlined":"","name":"contact","disabled":"","value":_vm.receivable.customer.first_name + ' ' + _vm.receivable.customer.last_name,"readonly":"","label":_vm.$tc('contact', 1)}})],1),_c('v-col',{class:{
        'pr-3': _vm.$vuetify.breakpoint.smAndUp
      },attrs:{"cols":"12","sm":"6","md":"4","lg":"3"}},[_c('v-text-field',{attrs:{"outlined":"","name":"phone","disabled":"","value":_vm.receivable.customer.phone,"readonly":"","label":_vm.$tc('phone', 1)}})],1),_c('v-col',{class:{
        'pr-3': _vm.$vuetify.breakpoint.smAndUp
      },attrs:{"cols":"12","sm":"6","md":"4","lg":"3"}},[_c('v-text-field',{attrs:{"outlined":"","name":"email","disabled":"","value":_vm.receivable.customer.email,"readonly":"","label":_vm.$tc('email', 1)}})],1),_c('v-col',{class:{
        'pr-3': _vm.$vuetify.breakpoint.smAndUp
      },attrs:{"cols":"12","sm":"6","md":"4","lg":"3"}},[(_vm.receivable.customer.address_set)?_c('v-text-field',{attrs:{"outlined":"","name":'SHIP',"disabled":"","value":_vm.receivable.customer.address_set[1].address1 +
          ', ' +
          _vm.receivable.customer.address_set[1].address2 +
          ', ' +
          _vm.receivable.customer.address_set[1].city +
          ', ' +
          _vm.receivable.customer.address_set[1].state,"readonly":"","label":_vm.$tc('customerAddress', 1)}}):_vm._e()],1),_c('v-col',{class:{
        'pr-3': _vm.$vuetify.breakpoint.smAndUp
      },attrs:{"cols":"12","sm":"6","md":"4","lg":"3"}},[(_vm.receivable.customer.address_set)?_c('v-text-field',{attrs:{"outlined":"","name":'BILL',"disabled":"","value":_vm.receivable.customer.address_set[0].address1 +
          ', ' +
          _vm.receivable.customer.address_set[0].address2 +
          ', ' +
          _vm.receivable.customer.address_set[0].city +
          ', ' +
          _vm.receivable.customer.address_set[0].state,"readonly":"","label":_vm.$tc('shippingAddress', 1)}}):_vm._e()],1)],1),_c('v-subheader',{class:{
      'primary--text': true,
      'font-weight-black': true
    }},[_vm._v(" "+_vm._s(_vm.$t('section', { value: _vm.$tc('invoice', 1) }))+" ")]),_c('v-row',_vm._l((_vm.invoices),function(invoice){return _c('v-col',{key:invoice.pk,staticClass:"listcard",attrs:{"cols":"12","sm":"6","md":"4"}},[_c('div',{class:{
          'primary--text': true,
          'font-weight-bold': true
        },on:{"click":function($event){return _vm.open(invoice.order)}}},[(_vm.toEdit != null)?_c('dialinvoice',{attrs:{"to-edit":_vm.toEdit},on:{"done":function($event){_vm.reload = true}},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}}):_vm._e(),_c('span',[_vm._v(" "+_vm._s(_vm.$t('invoice'))+" #"+_vm._s(invoice.invoice_number)+" ")]),_c('v-chip',{attrs:{"x-small":"","color":invoice.status === 'paid'
              ? 'success'
              : invoice.status === 'canceled'
              ? 'red'
              : 'info'}},[_vm._v(" "+_vm._s(_vm.$tc(invoice.status, 2))+" ")]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [(_vm.getPermissions([`Invoices.view_payments`]) || _vm.isAdmin)?_c('i-btn',_vm._g({attrs:{"title":_vm.$t('print', { value: _vm.$tc('invoice', 1) }),"small":"","color":"error","classes":"mx-1","outlined":"","icon":"fa-print"},on:{"click":function($event){return _vm.open(invoice.order)}}},on)):_vm._e()]}}],null,true)})],1),_c('div',{class:{
          'primary--text': true,
          'font-weight-bold': true,
          'subtitle-2': true,
          'mb-1': true
        }},[_vm._v(" "+_vm._s(`${_vm.$tc('order', 1)} #${invoice.extraInformation.orderNumber} - ${ invoice.extraInformation.orderName }`)+" ")]),_c('v-list',{staticClass:"py-0",staticStyle:{"margin-top":"-0.3rem"},attrs:{"dense":""}},[_c('v-list-group',{on:{"click":function($event){$event.stopPropagation();}},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-content',[_c('v-list-item-title',[_c('span',{staticClass:"primary--text subtitle-2 font-weight-black"},[_vm._v(_vm._s(_vm.$tc('product', 2)))])])],1)]},proxy:true}],null,true)},[_c('v-icon',{attrs:{"slot":"appendIcon","size":"12"},slot:"appendIcon"},[_vm._v("fas fa-arrow-down")]),_vm._l((invoice.extraInformation.products),function(param){return _c('v-list-item',{key:'param-' + param.code,attrs:{"dense":""}},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"primary--text",staticStyle:{"font-size":"12px","font-weight":"400"}},[_c('b',[_vm._v("·")]),_c('span',{staticClass:"font-weight-black"},[_vm._v(_vm._s(`${param.own_prod}:`))]),_c('br'),_c('i-money',{attrs:{"mode":"text","value":param.price}},[_c('span',{staticClass:"font-weight-black"},[_vm._v(_vm._s(`${_vm.$tc('price', 1)}:`))])]),_c('i-money',{attrs:{"mode":"text","value":param.tax}},[_c('span',{staticClass:"font-weight-black"},[_vm._v(_vm._s(`${_vm.$tc('tax', 1)}:`))])]),_c('br'),_c('i-money',{attrs:{"mode":"text","value":param.subtotal}},[_c('span',{staticClass:"font-weight-black"},[_vm._v(_vm._s(`${_vm.$tc('subtotal', 1)}:`))])]),_c('i-money',{attrs:{"mode":"text","value":param.total}},[_c('span',{staticClass:"font-weight-black"},[_vm._v(_vm._s(`${_vm.$tc('total', 1)}:`))])])],1)],1)],1)})],2)],1),_c('v-divider'),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"6","sm":"4","md":"3"}},[_c('span',{class:{
              'primary--text': true,
              'text-uppercase': true,
              'font-weight-black': true,
              'subtitle-2': true
            }},[_vm._v(" "+_vm._s(`${_vm.$tc('subtotal', 1)}:`)+" ")]),_c('br'),_c('i-money',{class:{
              'primary--text': true,
              'text-uppercase': true,
              'font-weight-black': true,
              'subtitle-2': true
            },attrs:{"value":invoice.subtotal,"mode":"text"}})],1),_c('v-col',{attrs:{"cols":"6","sm":"4","md":"3"}},[_c('span',{class:{
              'primary--text': true,
              'text-uppercase': true,
              'font-weight-black': true,
              'subtitle-2': true
            }},[_vm._v(" "+_vm._s(`${_vm.$tc('tax', 1)}:`)+" ")]),_c('br'),_c('i-money',{class:{
              'primary--text': true,
              'text-uppercase': true,
              'font-weight-black': true,
              'subtitle-2': true
            },attrs:{"value":invoice.tax,"mode":"text"}})],1),_c('v-col',{attrs:{"cols":"6","sm":"4","md":"3"}},[_c('span',{class:{
              'primary--text': true,
              'text-uppercase': true,
              'font-weight-black': true,
              'subtitle-2': true
            }},[_vm._v(" "+_vm._s(`${_vm.$tc('total', 1)}:`)+" ")]),_c('br'),_c('i-money',{class:{
              'primary--text': true,
              'text-uppercase': true,
              'font-weight-black': true,
              'subtitle-2': true
            },attrs:{"value":invoice.total,"mode":"text"}})],1),_c('v-col',{attrs:{"cols":"6","sm":"4","md":"3"}},[_c('span',{class:{
              'primary--text': true,
              'text-uppercase': true,
              'font-weight-black': true,
              'subtitle-2': true
            }},[_vm._v(" "+_vm._s(`${_vm.$tc('balance', 1)}:`)+" ")]),_c('br'),_c('i-money',{class:{
              'primary--text': true,
              'text-uppercase': true,
              'font-weight-black': true,
              'subtitle-2': true
            },attrs:{"value":invoice.balance,"mode":"text"}})],1)],1)],1)}),1),_c('v-subheader',{class:{
      'primary--text': true,
      'font-weight-black': true
    }},[_vm._v(" "+_vm._s(_vm.$t('section', { value: _vm.$tc('payment', 1) }))+" ")]),(_vm.receivable.pk !== '')?_c('payments',{attrs:{"balance":_vm.receivable.balance,"receivable":_vm.receivable.pk},on:{"createdPayment":function($event){return _vm.updateValues()},"changeMode":function($event){return _vm.$emit('changeMode', $event)}}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }